import { client } from '../clients'
import type { DeviceReportDto } from '../types'

const EMPTY_RECORD: DeviceReportDto = {
  device: null,
  deviceId: '',
  deviceReport: null,
  grade: null,
  gradeId: '',
  id: null,
  testSession: null,
  testSessionId: ''
}

const get = (testSessionId: string) => client.get<
  DeviceReportDto
>(`/api/device-reports/test-session/${testSessionId}`)

export const deviceReports = {
  EMPTY_RECORD,
  get
}
