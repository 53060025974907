import { useNavigate } from '@solidjs/router'
import type { Component } from 'solid-js'
import { onCleanup, onMount } from 'solid-js'

import * as model from 'processes/integration'
import {
  IntegrationMessage,
  IntegrationMessageType
} from 'processes/integration'

export const withIntegration = (component: Component) => () => {
  let height = 0
  const navigate = useNavigate()

  const onHeightChange = () => {
    const { clientHeight, scrollHeight } = document.body
    const current = Math.max(clientHeight, scrollHeight) ?? 0

    if (current !== height) {
      model.postMessageFx({
        data: String(current),
        type: IntegrationMessageType.HeightChanged
      })

      height = current
    }
  }

  const onReceiveMessage = (event: MessageEvent<IntegrationMessage>) => {
    const { data, type } = event.data

    switch (type) {
    case IntegrationMessageType.DropSession:
      navigate('/')
      break

    case IntegrationMessageType.OpenSession:
      if (data) navigate(`/${data}`)
      break

    default:
      model.receiveMessage(event.data)
      break
    }
  }

  const observer = new MutationObserver(onHeightChange)

  onCleanup(() => {
    window.removeEventListener('message', onReceiveMessage)
    window.removeEventListener('resize', onHeightChange)

    observer.disconnect()
  })

  onMount(() => {
    window.addEventListener('message', onReceiveMessage)
    window.addEventListener('resize', onHeightChange)

    observer.observe(window.document.body, {
      attributes: true,
      childList: true,
      subtree: true
    })
  })

  return component({})
}
