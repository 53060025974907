import { seq } from 'shared/api'

type LogType = 'Error' | 'Warning' | 'Info' | 'Debug'

const log = <Payload>(
  logType: LogType,
  message: string,
  payload: Payload,
  exception?: string
) => {
  return seq.post('api/events/raw?clef', {
    AssemblyName: 'SimpleCheckListSession',
    Environment: import.meta.env.MODE,
    Location: window.location.href,
    ...payload,
    '@l': logType,
    '@m': message,
    '@t': new Date().toJSON(),
    '@x': exception
  })
}

const error = <Payload>(
  message: string,
  payload: Payload,
  exception?: string
) => log('Error', message, payload, exception)

const warning = <Payload>(message: string, payload: Payload) =>
  log('Warning', message, payload)

const debug = <Payload>(message: string, payload: Payload) =>
  log('Debug', message, payload)

const info = <Payload>(message: string, payload: Payload) =>
  log('Info', message, payload)

export const seqLog = {
  debug,
  info,
  error,
  warning
}
