import { cva, cx } from 'cva'
import { Icon } from 'solid-heroicons'
import { chevronUpDown } from 'solid-heroicons/outline'
import type { JSX } from 'solid-js'
import { createSignal, Show } from 'solid-js'

import { Input, Typography } from '../../../atoms'

export interface SelectTriggerProps {
  onClick?: () => void
  onSearch?: (searchString: string) => void
  placeholder?: string
  showSearch?: boolean
  value: string
}

export function Trigger (props: SelectTriggerProps) {
  const [searchString, setSearchString] = createSignal('')

  const button = cx([
    'bg-white',
    'block',
    'border',
    'border-gray-300',
    'cursor-default',
    'focus:border-primary',
    'focus:outline-none',
    'focus:ring-2',
    'focus:ring-primary',
    'focus:ring-offset-1',
    'focus:z-10',
    'pl-4',
    'pr-10',
    'py-2',
    'relative',
    'rounded',
    'text-start',
    'w-full'
  ])

  const icon = cx(['h-5', 'w-5'])

  const iconWrapper = cx([
    'absolute',
    'flex',
    'inset-y-0',
    'items-center',
    'pointer-events-none',
    'pr-2',
    'right-0'
  ])

  const input = cva('focus:placeholder:text-neutral-400', {
    variants: {
      value: {
        false: ['placeholder:text-neutral-400'],
        true: ['placeholder:text-black']
      }
    }
  })

  const value = cx(['block', 'truncate'])

  const onInput: NonNullable<
    JSX.InputHTMLAttributes<HTMLInputElement>['onInput']
  > = event => {
    props.onSearch?.(event.currentTarget.value)
    setSearchString(event.currentTarget.value)
  }

  return (
    <Show
      fallback={(
        <button
          class={button}
          onClick={props.onClick}
          type='button'
        >
          <Typography.Text class={value}>
            {props.value || props?.placeholder}
          </Typography.Text>

          <Typography.Text class={iconWrapper}>
            <Icon
              class={icon}
              path={chevronUpDown}
            />
          </Typography.Text>
        </button>
      )}
      keyed
      when={props.showSearch}
    >
      <Input
        class={input({ value: !!props.value })}
        onClick={props.onClick}
        onBlur={() => setSearchString('')}
        onInput={onInput}
        placeholder={props.value || props?.placeholder}
        value={searchString()}
      />
    </Show>
  )
}
