import { cva, cx } from 'cva'
import { Show } from 'solid-js'
import type { JSX, ParentProps } from 'solid-js'
import { Dynamic } from 'solid-js/web'

export interface TypographyBaseProps {
  as: string
  class?: string
  classList?: Record<string, boolean | undefined>
  code?: boolean
  deleted?: boolean
  disabled?: boolean
  level?: 1 | 2 | 3 | 4 | 5
  mark?: boolean
  onClick?: () => void
  italic?: boolean
  strong?: boolean
  type?: 'danger' | 'secondary' | 'success' | 'warning'
  underline?: boolean
}

export type TypographyTextProps = Omit<TypographyBaseProps, 'as' | 'level'>
export type TypographyTitleProps = Omit<TypographyBaseProps, 'as'>

function TypographyBase (props: ParentProps<TypographyBaseProps>) {
  const classes = cva('', {
    variants: {
      disabled: {
        false: 'cursor-auto',
        true: ['cursor-not-allowed', 'text-gray-300']
      },
      italic: {
        false: 'not-italic',
        true: 'italic'
      },
      level: {
        0: 'text-base',
        1: 'text-4xl',
        2: 'text-3xl',
        3: 'text-2xl',
        4: 'text-xl',
        5: 'text-base'
      },
      type: {
        danger: 'text-red-500',
        secondary: 'text-neutral-500',
        success: 'text-green-500',
        warning: 'text-yellow-500'
      },
      underline: {
        false: 'no-underline',
        true: 'underline'
      }
    }
  })

  const withWrapper = (children: JSX.Element) => {
    const elements = ['code', 'del', 'mark', 'strong']

    const wrapper = elements.find(prop => {
      return props?.[prop as keyof TypographyBaseProps]
    })

    return (
      <Show
        fallback={children}
        when={wrapper}
      >
        <Dynamic
          component={wrapper}
          children={children}
        />
      </Show>
    )
  }

  return (
    <Dynamic
      class={cx([props.class, classes({
        disabled: props.disabled,
        italic: props.italic,
        level: props.level ?? 0,
        type: props.type,
        underline: props.underline
      })])}
      classList={props.classList}
      children={withWrapper(props.children)}
      component={props.as}
      onClick={props.onClick}
    />
  )
}

function Paragraph (props: ParentProps<TypographyTextProps>) {
  return (
    <Dynamic
      as='p'
      class={cx(['text-base', props.class])}
      classList={props.classList}
      component={TypographyBase}
      {...props}
    />
  )
}

function Title (props: ParentProps<TypographyTitleProps>) {
  return (
    <Dynamic
      as={`h${props.level}`}
      class={cx(['font-medium', props.class])}
      classList={props.classList}
      component={TypographyBase}
      level={props.level ?? 1}
      {...props}
    />
  )
}

function Text (props: ParentProps<TypographyTextProps>) {
  return (
    <Dynamic
      as='span'
      class={cx(['text-base', props.class])}
      classList={props.classList}
      component={TypographyBase}
      {...props}
    />
  )
}

// noinspection JSUnusedGlobalSymbols
export const Typography = { Paragraph, Text, Title }
