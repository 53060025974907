import type { Grade, TreeNodeTest } from 'shared/types'

export enum Status {
  Done = 'Done',
  Fail = 'Fail',
  NoQuestions = 'NoQuestions',
  Pending = 'Pending'
}

export type Answers = Record<string, Array<0 | 1>>

export type Result = Pick<TreeNodeTest, 'description' | 'question'> & {
  answer: string
  grade: Grade | null
}
