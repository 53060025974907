import { useRoutes } from '@solidjs/router'

import { routes } from 'pages'
import { withProviders } from './providers'
import './styles/global.css'

function App () {
  const Routes = useRoutes(routes)

  return <Routes />
}

export default withProviders(App)
