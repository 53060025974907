import { cx } from 'cva'
import type { JSX } from 'solid-js'

export type InputProps = Omit<JSX.InputHTMLAttributes<HTMLInputElement>, 'type'>

export function Input (props: InputProps) {
  const classes = cx([
    'bg-white',
    'block',
    'border',
    'border-gray-300',
    'focus:border-primary',
    'focus:outline-none',
    'focus:z-10',
    'px-4',
    'py-2',
    'relative',
    'rounded',
    'w-full'
  ])

  return (
    <input
      {...props}
      class={cx([classes, props.class])}
      type='text'
    />
  )
}
