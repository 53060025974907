import axios from 'axios'

export const client = axios.create({
  baseURL: import.meta.env.APP_API_URL ?? '',
  headers: {
    'Accept-Language': 'ru-RU'
  },
  params: {
    culture: 'ru-ru'
  }
})
