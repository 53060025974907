import { createEffect, createEvent, createStore, sample } from 'effector'

import * as quiz from 'features/quiz'
import * as creationForm from 'entities/session'
import api, { type UpdateEstimatedSessionParams } from 'shared/api'
import type { Device, TestSession } from 'shared/types'

export enum IntegrationMessageType {
  DeviceNotFound = 'DeviceNotFound',
  DropSession = 'DropSession',
  HeightChanged = 'HeightChanged',
  HomeButtonClicked = 'HomeButtonClicked',
  InitializeConnection = 'InitializeConnection',
  NotRedeemableDevice = 'NotRedeemableDevice',
  OpenSession = 'OpenSession',
  PriceChanged = 'PriceChanged',
  ResetSession = 'ResetSession',
  SessionDone = 'SessionDone',
  SendSessionId = 'SendSessionId',
  SetDeviceData = 'SetDeviceData'
}

export interface IntegrationMessage {
  data?: string
  type: IntegrationMessageType
}

export interface DeviceParams extends Pick<Device, 'modelId'> {
  basicPrice: number | null
  cityId: TestSession['cityId']
  deviceMemoryId: NonNullable<Device['deviceMemoryId']>
  imei?: string
  serialNumber?: string
}

export const $isIntegrated = createStore(false)

export const getDeviceFx = createEffect(({
  deviceMemoryId: memoryId,
  modelId
}: DeviceParams) => api.devices.findByModelAndMemory({ memoryId, modelId }))

export const postMessageFx = createEffect((message: IntegrationMessage) => {
  if (
    'ReactNativeWebView' in window &&
    window.ReactNativeWebView &&
    typeof window.ReactNativeWebView === 'object' &&
    'postMessage' in window.ReactNativeWebView &&
    window.ReactNativeWebView.postMessage &&
    typeof window.ReactNativeWebView.postMessage === 'function'
  ) {
    window.ReactNativeWebView.postMessage(JSON.stringify(message))
  }

  window.parent?.postMessage(message, { targetOrigin: '*' })
})

export const receiveMessage = createEvent<IntegrationMessage>()

// отправляет идентификатор сессии после ее создания
sample({
  clock: creationForm.startSessionFx.doneData,
  filter: Boolean,
  fn: data => ({ data, type: IntegrationMessageType.SendSessionId }),
  target: postMessageFx
})

// сбрасывает все ответы в текущей сессии
sample({
  clock: receiveMessage,
  source: quiz.$session,
  filter: (session, { type }) => (
    type === IntegrationMessageType.ResetSession && !!session?.id
  ),
  fn: (session): UpdateEstimatedSessionParams => ({
    done: false,
    testImages: [],
    testResults: [],
    testSessionId: session?.id ?? ''
  }),
  target: quiz.updateSessionFx
})

// находит устройство по модели и памяти
sample({
  clock: receiveMessage,
  filter: ({ data, type }) => {
    if (type !== IntegrationMessageType.SetDeviceData) return false
    const requiredParamsKeys = ['cityId', 'deviceMemoryId', 'modelId']

    return requiredParamsKeys.every(key => key in JSON.parse(data ?? ''))
  },
  fn: ({ data }) => JSON.parse(data ?? '') as DeviceParams,
  target: getDeviceFx
})

// устанавливает флаг об интеграции приложения
sample({
  clock: receiveMessage,
  filter: ({ type }) => type === IntegrationMessageType.InitializeConnection,
  fn: () => true,
  target: $isIntegrated
})

// устанавливает поля в форму создания
sample({
  clock: getDeviceFx.done,
  filter: ({ result }) => !!result.data,
  fn: ({ params, result }) => ({
    basicPrice: params.basicPrice,
    cityId: params.cityId ?? '',
    deviceId: result.data,
    imei: params.imei,
    serialNumber: params.serialNumber
  }),
  target: creationForm.setSessionFields
})

// уведомляет об отсутствии устройсва в базе тестирования
sample({
  clock: getDeviceFx.fail,
  fn: () => ({ type: IntegrationMessageType.DeviceNotFound }),
  target: postMessageFx
})

// отправляет стоимость выкупа при ее изменении
sample({
  clock: quiz.updateSessionFx.done,
  filter: ({ params }) => typeof params.done === 'boolean' && params.done,
  fn: ({ result }): IntegrationMessage => ({
    data: String(result.data.price ?? 0),
    type: IntegrationMessageType.PriceChanged
  }),
  target: postMessageFx
})

// уведомляет о завершении сессии
sample({
  clock: quiz.$status,
  filter: status => [quiz.Status.Done, quiz.Status.Fail].includes(status),
  fn: status => ({
    data: status === quiz.Status.Done ? 'true' : undefined,
    type: status === quiz.Status.Done
      ? IntegrationMessageType.SessionDone
      : IntegrationMessageType.NotRedeemableDevice
  }),
  target: postMessageFx
})

// уведомляет о нажатии на кнопку "Домой"
sample({
  clock: quiz.homeButtonClicked,
  fn: () => ({ type: IntegrationMessageType.HomeButtonClicked }),
  target: postMessageFx
})
