import type { RouteDefinition } from '@solidjs/router'

import { lazy } from 'shared/lib'

export const routes: RouteDefinition[] = [
  {
    component: lazy(() => import('./home')),
    path: '/'
  },
  {
    component: lazy(() => import('./not-found')),
    path: '*'
  },
  {
    component: lazy(() => import('./not-found')),
    path: '/404'
  },
  {
    component: lazy(() => import('./session')),
    path: '/:sessionId'
  }
]
