import { cx } from 'cva'
import { Icon } from 'solid-heroicons'
import { paperClip, trash } from 'solid-heroicons/outline'

export type FileProps = Pick<File, 'name'> & {
  onClick?: () => void
}

export function File (props: FileProps) {
  const classes = cx([
    'cursor-pointer',
    'flex',
    'flex-row',
    'gap-2',
    'group/file',
    'hover:bg-red-100',
    'hover:text-red-600',
    'items-center',
    'px-2',
    'py-1',
    'rounded',
    'self-start',
    'text-sm',
    'transition'
  ])

  const onClick = () => props.onClick?.()

  return (
    <div
      class={classes}
      onClick={onClick}
    >
      <Icon
        class='h-4 w-4'
        path={paperClip}
      />

      <span class='group-hover/file:text-red-600 mr-2 text-primary'>
        {props.name}
      </span>

      <Icon
        class={cx([
          'group-hover/file:visible',
          'h-4',
          'invisible',
          'text-red-600',
          'transition',
          'w-4'
        ])}
        path={trash}
      />
    </div>
  )
}
