import type { Device } from 'shared/types'
import { client } from '../clients'

const getMany = (modelId?: string) => {
  const query = new URLSearchParams()

  if (modelId) query.append('modelId', modelId)

  return client.get<Device[]>(`/estimate/devices?${query.toString()}`)
}

const findByModelAndMemory = (params: { memoryId: string; modelId: string }) =>
  client.get<string>('/devices/get-id', { params })

export const devices = {
  findByModelAndMemory,
  getMany
}
