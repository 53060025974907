import { createEffect, createEvent, createStore, sample } from 'effector'

import api, { type SessionCreatingParams } from 'shared/api'
import { errorsShape, validateForm } from '../lib'

const reset = createEvent()
const setSessionFields = createEvent<Partial<SessionCreatingParams>>()
const startSessionFx = createEffect(api.sessions.create)
const startSession = createEvent()

const $errors = createStore(errorsShape)
const $loading = startSessionFx.pending
const $session = createStore<SessionCreatingParams>(api.sessions.EMPTY_RECORD)
const $showErrors = createStore(false)

sample({
  clock: reset,
  target: [
    $errors.reinit,
    $session.reinit,
    $showErrors.reinit
  ]
})

sample({
  clock: setSessionFields,
  source: $session,
  fn: (session, changes) => ({ ...session, ...changes }),
  target: $session
})

sample({
  clock: startSession,
  source: $session,
  target: startSessionFx
})

sample({
  clock: startSessionFx.fail,
  source: $session,
  fn: (fields, { params: changes }) => validateForm({ ...fields, ...changes }),
  target: $errors
})

sample({
  clock: setSessionFields,
  source: $session,
  fn: (fields, changes) => validateForm({ ...fields, ...changes }),
  target: $errors
})

sample({
  clock: startSessionFx.fail,
  fn: () => true,
  target: $showErrors
})

sample({
  clock: startSessionFx.done,
  fn: () => false,
  target: $showErrors
})

export {
  $errors,
  $loading,
  $session,
  $showErrors,
  reset,
  setSessionFields,
  startSession,
  startSessionFx
}
