import { cx } from 'cva'
import { Icon } from 'solid-heroicons'
import { check } from 'solid-heroicons/outline'
import type { ParentProps } from 'solid-js'

import { Typography } from '../../../atoms'

export interface SelectOptionProps {
  onClick?: () => void
  isActive?: boolean
  value: number | string
}

export function Option (props: ParentProps<SelectOptionProps>) {
  const icon = cx(['h-5', 'w-5'])

  const iconWrapper = cx([
    'absolute',
    'flex',
    'inset-y-0',
    'items-center',
    'pr-4',
    'right-0',
    'text-primary'
  ])

  const wrapper = cx([
    'cursor-pointer',
    'hover:bg-primary/10',
    'hover:text-primary',
    'pl-4',
    'pr-9',
    'py-2',
    'relative',
    'select-none',
    'text-gray-900',
    'transition'
  ])

  const value = cx(['block', 'font-normal', 'truncate'])

  const onClick = () => props.onClick?.()

  return (
    <li
      class={wrapper}
      onClick={onClick}
    >
      <Typography.Text class={value}>
        {props.children}
      </Typography.Text>

      <Typography.Text
        class={iconWrapper}
        classList={{ hidden: !props.isActive }}
      >
        <Icon
          class={icon}
          path={check}
        />
      </Typography.Text>
    </li>
  )
}
