export enum TestingProviders {
  ThreeU = 'ThreeU',
  NoProvider = 'NoProvider'
}

export enum TestResultTypes {
  YesOrNo = 'YesOrNo',
  SingleAnswer = 'SingleAnswer'
}

export enum TestTypes {
  Visual = 'Visual',
  External = 'External'
}

export type City = Entity & Nameable

export interface Contract extends Entity, Nameable {
  cost: number
  departmentCode: string
  issuedDate: string
  issuer: string
  passportNumber: string
  passportSerial: string
  phone: string
  registrationAddress: string
  session: TestSession
  testSessionId: string | null
}

export interface Device extends Entity {
  deviceMemory: DeviceMemory | null
  deviceMemoryId: string | null
  modelId: string
  modelName: string
}

export interface DeviceMemory extends Entity, Nameable, Sortable {
  pbModelMemoryId: string | null
}

export interface DeviceReport {
  accelerometer: boolean | null
  activated: string
  aModelNumber: string
  applePay: boolean | null
  autoFocus: boolean | null
  backCameraNsys: boolean | null
  backFlash: boolean | null
  barometer: boolean | null
  batteryLife: string
  batterySerialNum: string
  bluetooth: boolean | null
  bluetoothAddress: string
  bottomMic: boolean | null
  carrierStatus: string
  cellularAddress: string
  chargeTimes: string
  color: string
  compass: boolean | null
  device: Device | null
  deviceId: string | null
  faceId: boolean | null
  fingerPrint: boolean | null
  flash: boolean | null
  frontCamera: string
  frontCameraNsys: boolean | null
  frontMic: boolean | null
  geolocation: boolean | null
  grade: string
  gyroscope: boolean | null
  id: string
  iMEI: string
  jailBroken: string
  lcdPixels: boolean | null
  lightSensorAndroid: boolean | null
  lightSensorIOs: boolean | null
  mainboardSerialNum: string
  manufacturer: string
  mDM: string
  memory: string
  modelNumber: string
  multiTouch: boolean | null
  name: string
  network: boolean | null
  powerBtn: boolean | null
  productionDate: string
  proximity: boolean | null
  qi: boolean | null
  rAM: string
  rearCamera: string
  regionISO3: string
  reportDate: string
  ringSilent: boolean | null
  salesArea: string
  salesModel: string
  score: string
  screenSerialNum: string
  serialNumber: string
  sim1: boolean | null
  testSession: TestSession | null
  testSessionId: string
  touch3D: boolean | null
  touchId: boolean | null
  touchScreen: boolean | null
  trueDepthCamera: boolean | null
  ultraWideCamera: boolean | null
  verifySerialNumber: string
  verifyUdid: string
  version: string
  videoMic: boolean | null
  volumeDown: boolean | null
  volumeUp: boolean | null
  warrantyDate: string
  wiFiAddress: string
  wiFiModule: string
  wiFi: boolean | null
}

export type DiagnosticGoal = Entity & Nameable & Sortable

export interface Entity {
  id: string
}

export interface Grade extends Entity, Nameable {
  downGrade: boolean
  isRedeemable: boolean
  priority: number
}

export interface Nameable {
  name: string
}

export type Partner = Entity & Nameable & Sortable

export interface Searchable {
  limit?: number
  name?: string
}

export interface Sortable {
  sort: number | null
}

export interface Test extends Entity, Nameable {
  answersCount: number
  order: number
  resultType: TestResultTypes
  testType: TestTypes
}

export interface TestResult extends Entity {
  chosenAnswer: TreeNodeTestAnswer | null
  chosenAnswerId: string | null
  nodeTest: TreeNodeTest
  nodeTestId: string
  results: Array<0 | 1>
  test: Test
  testId: string
  testSessionId: string
}

export interface TestSession extends Entity {
  approved: boolean
  basicPrice: number | null
  cityId: string | null
  contract: Contract
  dateTime: string
  device: Device
  deviceId: string
  diagnosticGoalId: string | null
  done: boolean
  number: string
  imei: string
  partnerId: string | null
  serialNumber: string
  testResults: TestResult[]
  userId: string
}

export interface TreeNode extends Entity, Nameable {
  modelId: string
  order: number
  parent: TreeNode
  parentId: string
}

export interface TreeNodeTest extends Entity, Sortable {
  answers: TreeNodeTestAnswer[]
  deleted: boolean
  description: string
  images: TreeNodeTestImage[]
  node: TreeNode
  nodeId: string
  percent: number
  question: string
  serviceId: string | null
  serviceName: string
  test: Test
  testId: string
}

export interface TreeNodeTestAnswer {
  index: number
  description: string
  grade: Grade | null
  gradeId: string | null
}

export interface TreeNodeTestImage {
  description: string
  order: number
  url: string
}
