import { AVITO_PARTNER_ID, BUYOUT_GOAL_ID } from 'shared/constants'
import { TestingProviders, type TestSession } from 'shared/types'
import { client } from '../clients'
import type {
  EstimatedSessionData,
  SessionCreatingParams,
  UpdateEstimatedSessionParams
} from '../types'

const EMPTY_RECORD: SessionCreatingParams = {
  basicPrice: null,
  cityId: '',
  deviceId: '',
  diagnosticGoalId: BUYOUT_GOAL_ID,
  imei: '',
  file: null,
  partnerId: AVITO_PARTNER_ID,
  providerType: TestingProviders.ThreeU,
  serialNumber: ''
}

const create = async ({
  ...params
}: SessionCreatingParams): Promise<string | null> => {
  const { providerType } = params

  if (providerType === TestingProviders.NoProvider) {
    const { file, providerType, ...payload } = params

    const {
      data
    } = await client.post<TestSession>('/estimate/session/start', payload)

    return data.id
  }

  if (providerType === TestingProviders.ThreeU) {
    const { file, imei, providerType, serialNumber, ...payload } = params
    const query = new URLSearchParams()

    for (const key in payload) {
      const value = payload?.[key as keyof typeof payload]

      if (value) query.append(key, String(value))
    }

    const data = new FormData()
    data.append('image', file as File)

    const {
      data: testSessionId
    } = await client.post<
      string
    >(`/api/device-reports/post-3u?${query.toString()}`, data)

    return testSessionId
  }

  return null
}

const get = (sessionId: string) =>
  client.get<EstimatedSessionData>(`/estimate/session/${sessionId}`)

const update = (params: UpdateEstimatedSessionParams) =>
  client.post<Omit<EstimatedSessionData,
    | 'session'
  >>('/estimate/session/save', params)

export const sessions = {
  EMPTY_RECORD,
  create,
  get,
  update
}
