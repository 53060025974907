import { ErrorBoundary, createMemo, type Component } from 'solid-js'
import { seqLog } from 'shared/lib'

export const withLogger = (component: Component) => () => {
  const fallback = createMemo((error: unknown) => {
    if (error instanceof Error) {
      const message = `[${error.name}]: ${error.message}`

      seqLog.error(message, {}, error.stack)
    }

    return null
  })

  return (
    <ErrorBoundary fallback={fallback}>
      {component({})}
    </ErrorBoundary>
  )
}
