import compose from 'compose-function'

import { withIntegration } from './with-integration'
import { withLogger } from './with-logger'
import { withMeta } from './with-meta'
import { withRouter } from './with-router'

export const withProviders = compose(
  withMeta,
  withRouter,
  withIntegration,
  withLogger
)
