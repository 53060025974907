import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample
} from 'effector'

import api, {
  type EstimatedSessionData,
  type UpdateEstimatedSessionParams
} from 'shared/api'
import type {
  DeviceReport,
  Nullable,
  TestResult,
  TreeNodeTest
} from 'shared/types'
import { type Answers, Result, Status } from '../types'

const getDeviceReportFx = createEffect(api.deviceReports.get)
const getQuestionsFx = createEffect(api.questions.getMany)
const getSessionFx = createEffect(api.sessions.get)
const updateSessionFx = createEffect(api.sessions.update)

const homeButtonClicked = createEvent()
const initialize = createEvent<string | null>()
const reset = createEvent()
const setAnswer = createEvent<Answers>()
const submit = createEvent()

const $data = createStore<Nullable<EstimatedSessionData>>({
  grade: null,
  price: 0,
  session: null
})

const $answers = createStore<Answers>({})
const $questions = createStore<TreeNodeTest[]>([])
const $providerData = createStore<DeviceReport | null>(null)
const $status = createStore<Status>(Status.Pending)

const $grade = $data.map(({ grade }) => grade)
const $price = $data.map(({ price }) => price ?? 0)
const $session = $data.map(({ session }) => session)

const $currentQuestion = combine([
  $answers,
  $session,
  $questions
], ([answers, session, questions]) => {
  const unanswered = session?.testResults?.find(({ results }) => {
    return !results.some(Boolean)
  })

  return questions.find(({ id }) => unanswered
    ? id === unanswered?.nodeTestId
    : !(id in answers)
  ) ?? null
})

const $currentQuestionIndex = combine([$questions, $currentQuestion], ([
  questions,
  currentQuestion
]) => questions.findIndex(({ id }) => currentQuestion?.id === id)) ?? 0

const $currentQuestionAnswerIndex = combine([
  $answers,
  $currentQuestion
], ([answers, question]) => {
  if (!question?.id) return null

  return answers?.[question.id]?.findIndex(Boolean) ?? null
})

const $loading = combine([
  getDeviceReportFx.pending,
  getQuestionsFx.pending,
  getSessionFx.pending,
  updateSessionFx.pending
], effects => effects.some(Boolean))

const $results = combine([$answers, $questions], ([results, questions]) => {
  const testResults: Result[] = []

  for (const { answers, description, id, question } of questions) {
    const answerIndex = results?.[id]?.findIndex(Boolean)
    const answer = answers?.[answerIndex]

    testResults.push({
      answer: answer?.description || '',
      description,
      grade: answer?.grade,
      question
    })
  }

  return testResults
})

// get current session data
sample({
  clock: initialize,
  filter: Boolean,
  target: getSessionFx
})

sample({
  clock: getSessionFx.doneData,
  fn: ({ data }) => data,
  target: $data
})

// set session status
sample({
  clock: getSessionFx.doneData,
  source: $session,
  fn: (session, { data }) => {
    const isRedeemable = data.grade.isRedeemable

    if (typeof isRedeemable === 'boolean' && !isRedeemable) return Status.Fail

    const currentSession = ('session' in data && !!data.session?.id)
      ? data.session
      : session

    return currentSession?.done ? Status.Done : Status.Pending
  },
  target: $status
})

sample({
  clock: getQuestionsFx.fail,
  fn: () => Status.NoQuestions,
  target: $status
})

sample({
  clock: getQuestionsFx.doneData,
  filter: ({ data }) => !data.length,
  fn: () => Status.NoQuestions,
  target: $status
})

// get questions
sample({
  clock: getSessionFx.doneData,
  fn: ({ data }) => data.session.deviceId,
  target: getQuestionsFx
})

sample({
  clock: getQuestionsFx.doneData,
  fn: ({ data }) => data.map(question => ({
    ...question,
    images: question.images.map(({ url, ...image }) => ({
      ...image,
      url: url ? `${import.meta.env.APP_STATIC_URL}${url}` : ''
    }))
  })),
  target: $questions
})

// pre-fill test results
sample({
  clock: getQuestionsFx.doneData,
  source: $data,
  fn: (data, { data: questions }): Nullable<EstimatedSessionData> => {
    const testResults: TestResult[] = []

    for (const nodeTest of questions) {
      const { answers, id, test, testId } = nodeTest

      const savedResult = data.session?.testResults.find(({ nodeTestId }) => {
        return nodeTestId === id
      })

      testResults.push(savedResult ?? {
        chosenAnswer: null,
        chosenAnswerId: null,
        id: '',
        nodeTest,
        nodeTestId: id,
        results: new Array<0 | 1>(answers.length).fill(0),
        test,
        testId,
        testSessionId: data.session?.id || ''
      })
    }

    return {
      ...data,
      session: {
        ...(data.session ?? {} as EstimatedSessionData['session']),
        testResults
      }
    }
  },
  target: $data
})

// merge pre-filled and test session answers
sample({
  clock: getSessionFx.doneData,
  source: $answers,
  fn: (answers, { data: { session } }) => {
    const computed: Answers = {}

    for (const { nodeTestId, results } of session.testResults) {
      computed[nodeTestId] = results
    }

    return { ...answers, ...computed }
  },
  target: $answers
})

// recalculate answers on session update
sample({
  clock: updateSessionFx.done,
  fn: ({ params: { testResults } }) => {
    const answers: Answers = {}

    for (const { nodeTestId, results } of testResults) {
      answers[nodeTestId] = results
    }

    return answers
  },
  target: $answers
})

// set answers
sample({
  clock: setAnswer,
  source: $answers,
  fn: (answers, changes) => ({ ...answers, ...changes }),
  target: $answers
})

// save session
sample({
  clock: submit,
  source: {
    $answers,
    $currentQuestionIndex,
    $grade,
    $questions,
    $session
  },
  fn: ({
    $answers,
    $currentQuestionIndex,
    $grade,
    $questions,
    $session
  }): UpdateEstimatedSessionParams => {
    const isLastQuestion = $questions?.length === $currentQuestionIndex + 1

    const testResults = []

    for (const nodeTestId in $answers) {
      testResults.push({
        nodeTestId,
        results: $answers?.[nodeTestId],
        testId: $questions?.find(({ id }) => nodeTestId === id)?.testId || ''
      })
    }

    return {
      done: isLastQuestion || !$grade?.isRedeemable,
      testImages: [],
      testResults,
      testSessionId: $session?.id || ''
    }
  },
  target: updateSessionFx
})

// reload session
sample({
  clock: updateSessionFx.done,
  filter: ({ params }) => typeof params.done === 'boolean',
  fn: ({ params }) => params.testSessionId,
  target: getSessionFx
})

sample({
  clock: $status,
  source: $session,
  filter: (session, status) => status === Status.Done && !!session?.id,
  fn: session => session?.id || '',
  target: getDeviceReportFx
})

sample({
  clock: getDeviceReportFx.doneData,
  fn: ({ data }) => data.deviceReport,
  target: $providerData
})

// reset
sample({
  clock: reset,
  target: [
    $answers.reinit,
    $data.reinit,
    $providerData.reinit,
    $questions.reinit,
    $status.reinit
  ]
})

export {
  $answers,
  $currentQuestion,
  $currentQuestionIndex,
  $currentQuestionAnswerIndex,
  $loading,
  $price,
  $providerData,
  $questions,
  $results,
  $session,
  $status,
  getSessionFx,
  homeButtonClicked,
  initialize,
  reset,
  setAnswer,
  submit,
  updateSessionFx
}
