import { cva } from 'cva'
import { useUnit } from 'effector-solid'
import type { ParentProps } from 'solid-js'

import * as integration from 'processes/integration'

export function BasicTemplate (props: ParentProps) {
  const isIntegrated = useUnit(integration.$isIntegrated,)

  const wrapper = cva(['h-full', 'min-h-full', 'mx-auto', 'p-4'], {
    variants: {
      integrated: {
        false: 'container',
        true: ''
      }
    }
  })

  return (
    <div class={wrapper({ integrated: isIntegrated() })}>
      {props.children}
    </div>
  )
}
