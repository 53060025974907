import api, { SessionCreatingParams } from 'shared/api'

export const errorsShape = Object
  .keys(api.sessions.EMPTY_RECORD)
  .reduce((errors, key) => ({
    ...errors,
    [key]: false
  }), {} as Record<keyof SessionCreatingParams, boolean>)

export function validateForm (fields: SessionCreatingParams) {
  return Object.entries(fields).reduce((errors, [key, value]) => {
    if (key !== 'imei') return { ...errors, [key]: !value }

    const imei = value as SessionCreatingParams['imei']

    return {
      ...errors,
      imei: !!imei?.length && imei.length < 15
    }
  }, errorsShape)
}
