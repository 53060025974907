import { Router } from '@solidjs/router'
import type { Component } from 'solid-js'

import { BasicTemplate } from 'shared/ui'

export const withRouter = (component: Component) => () => (
  <Router>
    <BasicTemplate>
      {component({})}
    </BasicTemplate>
  </Router>
)
