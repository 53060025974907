/** http://github.com/vitejs/vite/issues/11804 */
import { type Component, lazy as _lazy } from 'solid-js'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function lazy(fn: () => Promise<{ default: Component<any> }>) {
  return _lazy(() => fn().catch(importErrorHandler))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function importErrorHandler(error: string): { default: Component<any> } {
  // Get the last reload time from local storage and the current time
  const timeStr = sessionStorage.getItem('last-reload')
  const time = timeStr ? Number(timeStr) : null
  const now = Date.now()

  // If the last reload time is more than 10 seconds ago, reload the page
  const isReloading = !time || time + 10_000 < now
  if (isReloading) {
    console.log('New version for this module found. Reloading ...')
    sessionStorage.setItem('last-reload', String(now))
    window.location.reload()
    return { default: () => null }
  }

  throw new Error(error)
}
