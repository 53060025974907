import type { TreeNode } from 'shared/types'
import { client } from '../clients'

const getMany = (nodeId?: string) => {
  const query = new URLSearchParams()

  if (nodeId) query.append('nodeId', nodeId)

  return client.get<
    Omit<TreeNode, 'parent'>[]
  >(`/estimate/nodes?${query.toString()}`)
}

export const treeNodes = {
  getMany
}
